import { createApp } from "vue";
import { createPinia } from 'pinia';
import { createI18n } from 'vue-i18n';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { PiniaSharedState } from 'pinia-shared-state';
import VueMatomo from 'vue-matomo';
import VueHotjar from 'vue-hotjar-next';
import Vue3Tour from 'vue3-tour';
import 'vue3-tour/dist/vue3-tour.css';

import App from "./App.vue";
import "@/assets/scss/styles.scss";
import router from "./router";

import ClusterCenter from '@development/cluster-center';
import { FayeService, Config } from "@development/cluster-center";
import matomoConfig from '@/config/matomo';

window.Config = new Config({
    API_URL: process.env.VUE_APP_API_BASE_URL,
    support: {
        email: 'support@maxcluster.de',
        phone: '+495251414130',
        phone_formatted: '+49 5251 4141 30'
    },
    advice: {
        email: 'beratung@maxcluster.de',
        phone: '+4952514141350',
        phone_formatted: '+49 5251 4141 350'
    },
    backups: {
        lifetime: 14
    },
    bugsnag: {
        apiKey: process.env.VUE_APP_BUGSNAG_API_KEY || '',
        appVersion: process.env.VUE_APP_BUGSNAG_APP_VERSION || '',
        excludes: {
            statusCodes: [422, 404, 403, 401],
        },
        autoTrackSessions: false,
        collectUserIp: false
    },
    braintree: {
        api_key: process.env.VUE_APP_BRAINTREE_PRIVATE_KEY || ''
    },
    faye: {
        base_url: process.env.VUE_APP_FAYE_BASE_URL
    },
    auth: {
        logout_redirect: { name: 'login' }
    },
    requiredTaxIdCountries: [
        'AT', 'BE', 'BG', 'HR',
        'CY', 'CZ', 'DK', 'EE',
        'FI', 'FR', 'GR', 'HU',
        'IE', 'IT', 'LV', 'LT',
        'LU', 'MT', 'NL', 'PL',
        'PT', 'RO', 'SK', 'SI',
        'ES', 'SE'
    ]
});

window.Broker = new FayeService(window.Config.get('faye').base_url);

import '@development/cluster-center/src/utils/function';
import LocalizationService from "@development/cluster-center/src/services/Localization/LocalizationService";
import * as messages from '@development/cluster-center/src/assets/lang';
import * as appMessages from '@/assets/lang';
import {clusterCenterConfiguration} from "@/config/clusterCenter";
import {vueComponentLibraryConfiguration} from "@/config/vueComponentLibrary";
import {appConfiguration} from "@/config/app";
import {mergeObjects} from "@development/cluster-center/src/composables/helper.js";

window.Config = new Config(window.mergeDeep(
    appConfiguration,
    clusterCenterConfiguration
));

const mergedMessages = LocalizationService.getMessages(window.mergeDeep(messages, appMessages));
const i18n = createI18n({
    warnHtmlInMessage: appConfiguration.i18n.warnHtmlInMessage,
    // default locale
    locale: 'de',
    fallbackLocale: 'de',
    globalInjection: true,
    // translations (NOTE: It HAS to be called "messages" !!!!)
    messages: mergedMessages,
    numberFormats: {
        de: {
            currency: {
                style: 'currency', currency: 'EUR', notation: 'standard', currencyDisplay: 'symbol'
            },
            currencyLong: {
                style: 'currency', currency: 'EUR', notation: 'standard', currencyDisplay: 'symbol',
                minimumFractionDigits: 4, maximumFractionDigits: 4
            },
            decimal: {
                style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
            },
            percent: {
                style: 'percent', useGrouping: false
            },
            percentLong: {
                style: 'percent', useGrouping: false, minimumFractionDigits: 1, maximumFractionDigits: 1
            },
            thousands: {
                style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0
            },
        },
        en: {
            currency: {
                style: 'currency', currency: 'EUR', notation: 'standard'
            },
            currencyLong: {
                style: 'currency', currency: 'EUR', notation: 'standard',
                minimumFractionDigits: 4, maximumFractionDigits: 4
            },
            decimal: {
                style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
            },
            percent: {
                style: 'percent', useGrouping: false
            },
            percentLong: {
                style: 'percent', useGrouping: false, minimumFractionDigits: 1, maximumFractionDigits: 1
            },
            thousands: {
                style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0
            },
        }
    },
    datetimeFormats: {
        de: {
            partner: {
                year: 'numeric',
                month: 'long',
            },
            short: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            },
            long: {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
                hour: 'numeric',
                minute: 'numeric'
            },
            time: {
                hour: 'numeric',
                minute: 'numeric'
            },
            time_with_seconds: {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            }
        },
        en: {
            partner: {
                year: 'numeric',
                month: 'long',
            },
            short: {
                year: 'numeric', month: 'short', day: 'numeric'
            },
            long: {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                weekday: 'short',
                hour: 'numeric',
                minute: 'numeric'
            },
            time: {
                hour: 'numeric',
                minute: 'numeric'
            },
            time_with_seconds: {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            }
        }
    }
});

window.Broker = new FayeService(window.Config.get('faye').base_url);
window.Config.set('i18n', i18n.global);


const app = createApp(App);
const pinia = createPinia();
pinia.use(
    piniaPluginPersistedstate,
    PiniaSharedState({
        // Enables the plugin for all stores. Defaults to true.
        enable: false,
        // If set to true this tab tries to immediately recover the shared state from another tab. Defaults to true.
        initialize: false,
        // Enforce a type. One of native, idb, localstorage or node. Defaults to native.
        type: 'localstorage',
    }),
);

app.config.globalProperties.$app = appConfiguration;

if (!app.config.globalProperties?.$t) {
    app.config.globalProperties.$t = i18n.global.t;
}

app.use(router);
app.use(i18n);

app.use(pinia);
app.use(window.Config.initBugsnag());
app.use(ClusterCenter, clusterCenterConfiguration, vueComponentLibraryConfiguration);

if (window.Config.isDemo()) {
    app.use(Vue3Tour);
} else {
    app.component('v-tour', {});
}

if (window.Config.isProduction()) {
    app.use(VueMatomo, mergeObjects(matomoConfig, {router: router}));
    app.use(VueHotjar, {
        id: parseInt(process.env.VUE_APP_HOTJAR_ID),
        isProduction: window.Config.isProduction(),
        snippetVersion: 6
    });
}

app.mount("#app");