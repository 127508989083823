<template>
    <MoleculeForm @submit.prevent="submit">
        <div class="row">
            <div class="col-12 mb-3">
                <h4>{{ $t('profile.personal_data.contact_data') }}</h4>
            </div>
            <div class="col-2">
                <AtomSelect
                    :options="genderOptions"
                    v-model="gender"
                    label="label"
                    track-by="value"
                    searchable
                    close-on-select
                    :show-labels="false"
                    :allow-empty="false"
                    :placeholder="$t('profile.personal_data.gender_label')"
                    :label-text="$t('profile.personal_data.gender_label')"
                    :animated-label="false"
                />
            </div>
            <div class="col-9"></div>
            <div class="col-6 mt-4">
                <AtomInput :animated-label="false" required :label="$t('profile.personal_data.firstname')" v-model="state.firstname" :error="v$.firstname.$error">
                    <template #error-message>{{ $t(v$.firstname.$errors[0].$message) }}</template>
                </AtomInput>
            </div>
            <div class="col-6 mt-4">
                <AtomInput :animated-label="false" required :label="$t('profile.personal_data.surname')" v-model="state.surname" :error="v$.surname.$error">
                    <template #error-message>{{ $t(v$.surname.$errors[0].$message) }}</template>
                </AtomInput>
            </div>
            <div class="col-6 mt-4">
                <AtomInput :animated-label="false" :label="$t('profile.personal_data.phone')"  v-model="state.telephone" :error="v$.telephone.$error && !v$.telephone.$pending">
                    <template #error-message v-if="v$.telephone?.$errors[0]?.$message">
                        {{ $t(v$.telephone.$errors[0]?.$message) }}
                    </template>
                </AtomInput>
            </div>
            <div class="col-6 mt-4">
                <AtomInput :animated-label="false" :label="$t('profile.personal_data.mobile')" v-model="state.mobile" :error="v$.mobile.$error && !v$.mobile.$pending">
                    <template #error-message v-if="v$.mobile?.$errors[0]?.$message ">
                        {{ $t(v$.mobile.$errors[0]?.$message ) }}
                    </template>
                </AtomInput>
            </div>
            <div class="col-6 mt-4">
                <AtomInput :animated-label="false" :label="$t('profile.personal_data.company')" v-model="state.company" :error="v$.company.$error">
                    <template #error-message>{{ $t(v$.company.$errors[0].$message, {max: 255}) }}</template>
                </AtomInput>
            </div>
            <div class="col-6 mt-4">
                <AtomInput :animated-label="false" :label="$t('profile.personal_data.department')" v-model="state.department"></AtomInput>
            </div>
            <div class="col-6 mt-4">
                <AtomInput :animated-label="false" :label="$t('profile.personal_data.street_and_number')" v-model="state.street"></AtomInput>
            </div>
            <div class="col-6 mt-4">
                <div class="row">
                    <div class="col-4">
                        <AtomInput :animated-label="false" :label="$t('profile.personal_data.postcode')" class="mr-3" v-model="state.postcode" :error="v$.postcode.$error">
                            <template #error-message>{{ $t(v$.postcode.$errors[0].$message, {max: 255}) }}</template>
                        </AtomInput>
                    </div>
                    <div class="col-8">
                        <AtomInput :animated-label="false" :label="$t('profile.personal_data.city')" clas="w-100" v-model="state.city"></AtomInput>
                    </div>
                </div>
            </div>
            <div class="col-6 mt-4">
                <AtomSelect
                    class="d-inline-block"
                    :options="countryOptions"
                    v-model="country"
                    label="label"
                    track-by="value"
                    searchable
                    close-on-select
                    :show-labels="false"
                    :allow-empty="false"
                    :placeholder="$t('countries.de')"
                    :label-text="$t('profile.personal_data.country')"
                    :animated-label="false"
                >
                </AtomSelect>
            </div>
            <div class="col-6 mt-4">
                <AtomSelect
                    class="d-inline-block"
                    :selected="language"
                    :options="languageOptions"
                    v-model="language"
                    label="label"
                    track-by="value"
                    searchable
                    close-on-select
                    :allow-empty="false"
                    :show-labels="false"
                    :placeholder="$t('profile.personal_data.language')"
                    :label-text="$t('profile.personal_data.language')"
                    :animated-label="false"
                >
                </AtomSelect>
            </div>
            <div class="col-12 mt-4">
                <AtomButton type="submit" :disabled="actionIsRunning" :loading="actionIsRunning">
                    {{ $t('profile.personal_data.save_data') }}
                </AtomButton>
            </div>
        </div>
    </MoleculeForm>
</template>
<script setup>
    import {ResponseService, UserService} from "@development/cluster-center";
    import {required, maxLength, phone} from '@development/cluster-center/src/utils/validators';
    import {languages, countries} from "@development/cluster-center/src/assets/lang";
    import { useVuelidate } from '@vuelidate/core';
    import {computed, onMounted, reactive, ref, watch} from "vue";
    import { validate, resetValidation } from '@development/cluster-center/src/composables/validation';
    import { useErrorHandling } from '@development/cluster-center/src/composables/errors/default';
    import { mergeObjects } from "@development/cluster-center/src/composables/helper";

    const i18n = window.Config.get('i18n');
    const emit = defineEmits(['updated:contact']);
    const props = defineProps({
        user: Object
    });

    const state = reactive({
        firstname: null,
        surname: null,
        telephone: null,
        mobile: null,
        company: null,
        department: null,
        street: null,
        postcode: null,
        city: null,
        country: null,
        language: null
    });
    const rules = {
        firstname: {required},
        surname: {required},
        company: {maxLength: maxLength(255)},
        postcode: {maxLength: maxLength(255)},
        mobile: { phone },
        telephone: { phone }
    };
    const $externalResults = ref({});
    const v$ = useVuelidate(rules, state, { $externalResults });

    const language = ref({value: 'de', label: i18n.t('languages.de')});
    const country = ref({value: 'de', label: i18n.t('countries.de')});
    const gender = ref({value: 'male', label: i18n.t('profile.personal_data.male')});

    const prepare = () => {
        let user = props.user || {};

        if (window.isEmpty(user)) {
            return;
        }

        state.firstname = user?.firstname || '';
        state.surname = user?.surname || '';
        state.telephone = user?.telephone || '';
        state.mobile = user?.mobile || '';
        state.street = user?.street || '';
        state.postcode = user?.postcode || '';
        state.city = user?.city || '';
        state.department = user?.department || '';
        state.company = user?.company || '';

        language.value = {value: user?.language || 'de', label: i18n.t(`languages.${user?.language || 'de'}`)};
        country.value = {value: user?.country || 'de', label: i18n.t(`countries.${user?.country || 'de'}`)};
        gender.value = {value: user?.gender || 'male', label: i18n.t(`profile.personal_data.${user?.gender || 'male'}`)};
    };

    const genderOptions = computed(() => {
        return [
            {value: 'male', label: i18n.t('profile.personal_data.male')},
            {value: 'female', label: i18n.t('profile.personal_data.female')},
        ];
    });

    const languageOptions = computed(() => {
        let options = [];

        Object.keys(languages.de).forEach(l => {
            options.push({label: i18n.t(`languages.${l}`), value: l});
        });

        return options;
    });

    const countryOptions = computed(() => {
        let options = [];

        Object.keys(countries.de).forEach(c => {
            options.push({label: i18n.t(`countries.${c}`), value: c});
        });

        return options;
    });

    onMounted(() => prepare());

    const actionIsRunning = ref(false);
    const submit = async () => {
        await resetValidation(v$.value);

        if (!await validate(v$.value)) {
            return;
        }

        actionIsRunning.value = true;

        return await UserService.updateUser(prepareParams()).then(response => {
            if (Array.isArray(response)) {
                if (response[2] === 401) {
                    return;
                }

                if (response[2] !== 422) {
                    return useErrorHandling(response);
                }

                $externalResults.value = useErrorHandling(response);
                return;
            }

            let user = response.data;

            i18n.locale = user?.language || 'de';

            ResponseService.success(i18n.t('profile.personal_data.update_success'));

            language.value = {value: user.language, label: i18n.t(`languages.${user.language}`)};
            country.value = {value: user.country, label: i18n.t(`countries.${user.country}`)};
            gender.value = {value: user.gender, label: i18n.t(`profile.personal_data.${user.gender}`)};

            emit('updated:contact', user);

            resetValidation(v$.value);
        }).finally(() => {
            actionIsRunning.value = false;
        });
    };

    const prepareParams = () => {
        return mergeObjects(
            state,
            {
                gender: gender.value.value,
                language: language.value.value,
                country: country.value.value
            }
        );
    };

    watch(() => props.user,  () => {
        prepare();
    });

    watch(language, val =>  {
        state.language = val.value;
    });

    watch(country, val => {
        state.country = val.value;
    });

    watch(gender, val => {
        state.gender = val.value;
    });
</script>