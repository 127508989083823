<template>
    <MoleculeModal :close-icon="false" :escape-close="false" :backdrop-close="false" class="re-login" @close="close" :close-text="$t('app.back_to_login')" v-model="display" size="md">
        <template #modal-headline>
            <template v-if="!twoFactor">
                {{ $t('app.login_sub_title') }}
            </template>
            <template v-else>
                {{ $t('app.two_factor_sub_title') }}
            </template>
        </template>
        <MoleculeLoader v-if="actionIsRunning" size="md" />
        <div v-show="actionIsRunning === false">
            <LoginComponent
                forgot-password-new-tab
                ref="loginComponentRef"
                :forgot-password-route="{name: 'password-forgot'}"
                @auth:logged-in="getUserData"
                @auth:two-factor="twoFactor = true"
                v-if="!twoFactor"
                hide-forgot-password-link
            />
            <TwoFactorComponent
                ref="twoFactorComponentRef"
                @auth:logged-in="getUserData"
                @auth:two-factor-abort="twoFactor = false"
                v-else
            />
        </div>
        <template #actions>
            <AtomButton icon-right type="submit" class="py-2 px-4" @click.prevent="login" :disabled="actionIsRunning">
                {{ $t('auth.login') }}
                <template #button-right>
                    <AtomIcon name="caret-line-right" />
                </template>
            </AtomButton>
        </template>
    </MoleculeModal>
</template>
<script setup>
    import { ref, watch } from 'vue';
    import {useRouter} from "vue-router";
    import {userStore} from "@development/cluster-center";
    import {useColorMode} from "bootstrap-vue-next";

    const emit = defineEmits(['close', 'auth:rerender']);

    const props = defineProps({
        show: Boolean
    });

    const display = ref(props.show);
    const twoFactor = ref(false);

    watch(() => props.show, (value) => {
        display.value = value;
        twoFactor.value = false;
        loggedIn.value = false;
    });

    const $router = useRouter();
    const loggedIn = ref(false);
    const user = userStore();

    const close = () => {
        if (!props.show) {
            return;
        }

        if (loggedIn.value && display.value) {
            emit('auth:rerender');
            emit('close');
            return;
        }

        setTimeout(() => {
            const target = document.querySelector('html');
            const mode = useColorMode({
                selector: target,
            });

            mode.value = '';
        }, 500);

        user.reset();
        $router.push({name: 'login'});
        emit('close');
    };
    const getUserData = async () => {
        await user.getUserData();
        loggedIn.value = true;

        close();
    };

    const loginComponentRef = ref(null);
    const twoFactorComponentRef = ref(null);
    const actionIsRunning = ref(false);

    const login = async () => {
        actionIsRunning.value = true;

        if (twoFactor.value) {
            await twoFactorComponentRef.value.login();
        } else {
            await loginComponentRef.value.login();
        }

        actionIsRunning.value = false;
    };
</script>
<style>
    .re-login form .btn-primary {
        display: none;
    }
</style>