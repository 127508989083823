<template>
    <footer id="footer" class="mt-5">
        <div class="py-2" :class="containerClass">
            <div class="row d-flex pb4 footer-container">
                <div class="col-12 col-md-6 d-flex align-items-center">
                    <div class="col">
                        <router-link to="/" class="navbar-brand me-4">
                            <AtomImage :size="174" :src="require('@/assets/images/logo/logo_white_slogan.svg')" alt="maxcluster-logo" draggable="false" />
                        </router-link>
                    </div>
                </div>
                <div class="col justify-content-end d-flex flex-column">
                    <div class="row justify-content-end mb-auto d-flex support-row align-items-start">
                        <div class="col">
                            <strong>{{ $t('app.footer.advice') }} (09:00-18:00 Uhr)</strong>
                        </div>
                        <div class="col">
                            <AtomTextLink :href="`tel:${$cc.advice.phone}`">
                                <strong><AtomIcon name="phone-handset-line" /> {{ $cc.advice.phone_formatted }}</strong>
                            </AtomTextLink>
                        </div>
                        <div class="col">
                            <AtomTextLink :href="`mailto:${$cc.advice.email}`">
                                <strong><AtomIcon name="envelope-line" /> {{ $cc.advice.email }}</strong>
                            </AtomTextLink>
                        </div>
                    </div>
                    <ul class="footer-nav d-flex justify-content-end align-items-end">
                        <li><AtomTextLink rel="noopener" :href="link('knowledge_base')"  target="_blank">{{ $t('app.footer.knowledge_base') }}<AtomIcon class="me-0 ms-1" name="pop-out-line"/></AtomTextLink></li>
                        <li><AtomTextLink rel="noopener" :href="link('imprint')" target="_blank">{{ $t('app.footer.imprint') }}<AtomIcon class="me-0 ms-1" name="pop-out-line"/></AtomTextLink></li>
                        <li><AtomTextLink rel="noopener" :href="link('data_protection')" target="_blank">{{ $t('app.footer.data_protection') }}<AtomIcon class="me-0 ms-1" name="pop-out-line"/></AtomTextLink></li>
                        <li><AtomTextLink rel="noopener" :href="link('tos')" target="_blank">{{ $t('app.footer.tos') }}<AtomIcon class="me-0 ms-1" name="pop-out-line"/></AtomTextLink></li>
                        <li><AtomTextLink rel="noopener" :href="link('sla')" target="_blank">{{ $t('app.footer.service_level_agreement') }}<AtomIcon class="me-0 ms-1" name="pop-out-line"/></AtomTextLink></li>
                        <li><AtomTextLink rel="noopener" :href="link('guidelines')" target="_blank">{{ $t('app.footer.guidelines') }}<AtomIcon class="me-0 ms-1" name="pop-out-line"/></AtomTextLink></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
    import BaseComponent from "@/components/BaseComponent.vue";

    export default {
        extends: BaseComponent,
        name: 'FooterBar',
        props: {
            containerClass: String
        },
        methods: {
            link(item) {
                const locale = this.$i18n.locale;
                const links = {
                    knowledge_base: {
                        en: 'https://knowledge.maxcluster.de',
                        de: 'https://knowledge.maxcluster.de'
                    },
                    imprint: {
                        en: 'https://maxcluster.de/en/legal-imprint',
                        de: 'https://maxcluster.de/impressum'
                    },
                    data_protection: {
                        en: 'https://maxcluster.de/en/privacy-policy',
                        de: 'https://maxcluster.de/datenschutz'
                    },
                    tos: {
                        en: 'https://maxcluster.de/en/terms-conditions',
                        de: 'https://maxcluster.de/agb'
                    },
                    sla: {
                        en: 'https://maxcluster.de/en/sla',
                        de: 'https://maxcluster.de/sla'
                    },
                    guidelines: {
                        en: 'https://maxcluster.de/en/guidelines',
                        de: 'https://maxcluster.de/richtlinien'
                    }
                };

                return links[item][locale] || links[item].en;
            }
        },
    };
</script>
<style lang="scss" scoped>
    #footer {
        max-width: 100%;
        width: 100%;
        background: $footer-background;
        color: $white;
        position: relative;
        z-index: 5;
        flex-shrink: 0;

        .support-row {
            flex-wrap: nowrap;

            .col {
                flex: 0 0 auto;
                width: auto;
            }
        }

        a {
            color: $white;
            text-decoration: none;
            font-size: 14px;

            &:hover {
                color: $primary;
            }
        }
    }
    
    .footer-container {
        height: 65px;
    }

    .footer-nav {
        display: inline-block;
        list-style: none;
        margin-bottom: 0 !important;

        li {
            display: inline-block;

            &:not(:first-of-type) {
                margin-left: 1rem;
            }
        }
    }
</style>