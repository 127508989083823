import {computed, ref} from "vue";
import {partnerStore} from "@/stores/partnerStore";
import PartnerService from "@/services/Api/Accounting/PartnerService";
import {useRoute} from "vue-router";
import {getMonthNameList, getMonthShortNameList} from "@/utils/helpers";
import {ResponseService} from "@development/cluster-center";
import { mergeObjects } from "@development/cluster-center/src/composables/helper.js";

export function userPartnerInformation() {
    const currentCustomer = ref(null);
    const levels = ref([]);
    const provisions = ref([]);
    const i18n = window.Config.get('i18n');
    const route = useRoute();

    const currentLevels = computed(() => {
        if (!currentCustomer.value) {
            return levels.value;
        }

        return getCurrentPartnerShip().levels;
    });

    const getCurrentPartnerShip = () => {
        let partnerships = currentCustomer.value?.partnerships || [];
        let currentPartnership = partnerships.find(p => !p.end_data);

        if (levels.value && currentPartnership) {
            levels.value.forEach((level, index) => {
                if (currentPartnership.levels[index]) {
                    currentPartnership.levels[index]['name'] = level.name;
                    currentPartnership.levels[index]['trans'] = i18n.t(`partner.levels.${level.name}`);
                }
            });
        }

        if (currentPartnership) {
            return currentPartnership;
        }

        return currentCustomer.value?.partnerships[currentCustomer.value?.partnerships.length - 1];
    };

    const alreadyPayed = computed(() => {
        if (!provisions.value) {
            return 0;
        }

        return provisions.value.reduce((accumulator, object) => {
            return accumulator + object.amountNet;
        }, 0);
    });

    const monthlyData = ref({});
    const selectableYears = computed(() => {
        let data = monthlyData.value || {};
        let years = [];

        if (!data || window.isEmpty(data)) {
            return [];
        }

        [...Object.keys(data?.expected || {}), ...Object.keys(data?.cleared || {}), ...Object.keys(data?.open || {})].forEach(date => {
            let year = date.split('-')[0];

            if (!years.includes(year)) {
                years.push(year);
            }
        });

        if (years.length === 0) {
            years = [new Date().getFullYear()];
        }

        return years.sort().reverse();
    });

    const getPartnerCustomerData =  async function () {
        const store = partnerStore();

        return await PartnerService.getMine().then(response => {
            if (Array.isArray(response)) {
                return response;
            }

            const customers = response.data;

            if (route.params.partnerId) {
                currentCustomer.value = customers.find(partner => partner.id === parseInt(route.params.partnerId));
            }

            if (!currentCustomer.value && customers.length > 0) {
                currentCustomer.value = customers[0];
            }

            store.$patch({'currentCustomer': currentCustomer.value});

            return response;
        });
    };

    return {
        currentCustomer,
        currentLevels,
        levels,
        alreadyPayed,
        selectableYears,
        monthlyData,
        provisions,
        getPartnerCustomerData,
        getCurrentPartnerShip
    };
}

export async function getSalesMaterial() {
    const i18n = window.Config.get('i18n');
    const support = window.Config.get('support');

    return await PartnerService.getSalesMaterial().then(response => {
        if (Array.isArray(response)) {
            if (response[2] !== 401) {
                ResponseService.danger(i18n.t(response[1], {phone: support.phone_formatted, email: support.email }));
            }

            return {data: [], error: true};
        }

        return {data: (response?.data?.material || []), error: false};
    });
}

export async function getExpectedRevenue(id) {
    if (!id) {
        return 0;
    }

    return await PartnerService.getExpectedRevenue(id).then(response => {
        if (Array.isArray(response)) {
            return {data: 0, error: true};
        }

        return {data: (response?.data?.revenue || {revenue: 0}), error: false};
    });
}

export async function getProvisions(id, params = {}) {
    if (!id) {
        return [];
    }

    const i18n = window.Config.get('i18n');
    const support = window.Config.get('support');

    return await PartnerService.getProvisions(params, id).then(response => {
        if (Array.isArray(response)) {
            if (response[2] !== 401) {
                ResponseService.danger(i18n.t(response[1], {phone: support.phone_formatted, email: support.email }));
            }

            return {data: [], error: true};
        }

        return {data: (response?.data || []), error: false};
    });
}

    export function prepareData(monthlyData, status, currentYear) {
    let data = [];
    let monthShortName = getMonthShortNameList();
    let monthName = getMonthNameList();

    if (!monthlyData) {
        return [];
    }

    for (let i = 0; i < 12; i++) {
        let month = i + 1;

        if (month < 10) {
            month = '0' + month;
        }

        let value = 0;

        if (monthlyData[status]) {
            value = (monthlyData[status][`${currentYear}-${month}`] || 0);
        }

        data.push({
            month: monthShortName[i],
            fullDate: monthName[i] + ' ' + currentYear,
            value: value
        });
    }

    return data;
}

export async function getLevels() {
    return await PartnerService.getLevels().then(response => {
        if (Array.isArray(response)) {
            return [];
        }

        return response?.data?.levels || [];
    });
}

export function prepareCurrentLevel(partnership, amount) {
    let nextLevel = null;
    let reachedPercentage = 100;
    let levels = [
        'partner.levels.bronze',
        'partner.levels.silver',
        'partner.levels.gold',
        'partner.levels.platinum'
    ];

    const i18n = window.Config.get('i18n');

    let currentLevel = {
        reachedPercentage: 0,
        percentage: 0,
        limit: 0,
        currentAmount: amount,
        nextLevel: mergeObjects(partnership?.levels[0], {levelName: i18n.t(levels[0])}),
        levelName: ''
    };

    for (let i = 0; i < partnership?.levels.length; i++) {
        let level = partnership.levels[i];

        if (partnership.levels[i + 1]) {
            nextLevel = mergeObjects(partnership.levels[i + 1], {levelName: i18n.t(levels[i + 1])});
            reachedPercentage = 100 / nextLevel.limit * amount;
        }

        if (amount >= level.limit) {
            currentLevel = mergeObjects(currentLevel, {
                reachedPercentage: reachedPercentage,
                percentage: level.provision_percentage,
                currentAmount: amount,
                nextLevel: (i + 1) < partnership.levels.length ? nextLevel : {},
                levelName: i18n.t(levels[i]),
                limit: level.limit
            });
        }
    }

    if (currentLevel.reachedPercentage > 100) {
        currentLevel.reachedPercentage = 100;
    }

    return currentLevel;
}
