export default {
    de: {
        notifications: {
            notification_center: {
                titel: 'Notification Center',
                text: 'In unserem neuen Notification Center werden wir Sie ab sofort auf neue Funktionen und Benachrichtigungen im Application Center hinweisen.'
            },
            ui_customization: {
                titel: 'Einstellungen Oberfläche',
                text: 'Wir bieten ab sofort die Möglichkeit, das Application Center im Dark Mode sowie in der ganzen Breite des Screens zu verwenden.'
            },
            table_config: {
                titel: 'Neue Tabellenoptionen',
                text: 'Für eine bessere Übersicht in Tabellen können Tabellenspalten nun individuell ein- und ausgeblendet werden. Dabei ist zu beachten, dass diese Einstellung vorerst nur im Browser gespeichert wird.'
            },
            feedback: {
                titel: 'Ihre Meinung ist uns sehr wichtig',
                text: 'Wir hoffen, dass Sie mit unserem Service zufrieden sind! Ihre Meinung ist uns sehr wichtig, und wir möchten uns kontinuierlich verbessern. Können Sie sich einen Moment Zeit nehmen, um uns Ihr Feedback zu geben? Klicken Sie <a target="__blank" class="px-0" rel="external nofollow" href="https://survey-eu1.hsforms.com/1JzFKHeq3QcyQfls6Rto0FQf2v0q">hier</a>, um an unserer kurzen Umfrage teilzunehmen. Vielen Dank für Ihre Unterstützung!'
            },
            protection_cluster_control: {
                titel: 'Neues Feature in Cluster Control',
                text: 'Das cluster-control Feature Protection ist jetzt verfügbar. Damit können einzelne IP-Adressen oder IP- Ranges auf die Block- und Allowlist hinzugefügt oder entfernt werden. Die Listen lassen sich tabellarisch ausgeben.'
            },
            closing_application_center: {
                titel: 'Abschaltung altes Application Center',
                text: 'Am 27.01.2025 werden wir die Legacy-Variante unseres Application Centers abschalten. Wir legen damit den Fokus auf unser aktuelles, im modernen JavaScript-Framework Vue.js erstelltes User Interface. Hierdurch können wir Weiterentwicklungen schneller durchführen und noch besser auf Kundenwünsche hinsichtlich Nutzerfreundlichkeit eingehen.'
            },
            export_cluster_data: {
                titel: 'Export der Cluster-Daten',
                text: 'In unserer Übersicht haben Sie nun die Möglichkeit, ihre lesbaren Daten aus der Übersicht zu exportieren. Mit einem Klick auf den Button "Exportieren" in der Übersicht im Application Center exportieren Sie alle lesbaren Daten in ein .xlxs-Format und können Sie dann in der Datei weiter bearbeiten.'
            },
        },
        link: 'Anzeigen',
        feedback_link: 'Zur Umfrage',
        read_all: 'Alle gelesen',
        read_all_text: 'Sie haben {number} ungelesene Benachrichtigungen.'
    },
    en: {
        notifications: {
            notification_center: {
                titel: 'Notification Center',
                text: 'Our new Notification Center will keep you informed about new features and notifications in the Application Center.'
            },
            ui_customization: {
                titel: 'Display options',
                text: 'We now offer the option to use the Application Centre in dark mode and across the full width of the screen.'
            },
            table_config: {
                titel: 'New table options',
                text: 'For a better overview in tables, you can now show and hide table columns individually. Note that this setting is initially only saved in the browser.'
            },
            feedback: {
                titel: 'Your opinion is very important to us!',
                text: 'We hope you are happy with our service! Your opinion is very important to us and we are always looking to improve. Can you take a moment to give us your feedback? Click <a class="px-0" target="__blank" rel="external nofollow" href="https://survey-eu1.hsforms.com/1IGkVqUQpRi-cC3ypdaBDZwf2v0q">here</a> to take part in our short survey. Thank you for your help!'
            },
            protection_cluster_control: {
                titel: 'New feature in Cluster Control',
                text: 'The cluster-control feature Protection is now available. This allows individual IP addresses or IP ranges to be added to or removed from the block and allow lists. The lists can be displayed in tabular form.'
            },
            closing_application_center: {
                titel: 'Shutdown of the old Application Center',
                text: 'On 27/01/2025 we will be shutting down the old version of our Application Center. This will allow us to focus on our current user interface, which is built in the modern JavaScript framework Vue.js. This will allow us to develop more quickly and respond even better to customer requests for usability.'
            },
            export_cluster_data: {
                titel: 'Exporting cluster data',
                text: 'In our overview, you now have the option of exporting your readable data from the overview. By clicking on the ‘Export’ button in the overview in the Application Centre, you export all readable data in .xlxs format and can then edit it further in the file.'
            },
        },
        link: 'Show',
        feedback_link: 'To the survey',
        read_all: 'Read all',
        read_all_text: 'You have {number} unread notifications.'
    }
};
