<template>
    <div class="row">
        <div class="col-12 mb-3">
            <h4>{{ $t('profile.security.password') }}</h4>
        </div>
        <div class="col-6">
            <AtomInput :animated-label="false" :label="$t('profile.security.password')" class="password" type="password" v-model="currentPassword" disabled />
        </div>
        <div class="col-6">
            <AtomButton class="me-3 mt-4" @click.prevent="showForm = true">
                {{ $t('profile.security.change_password') }}
            </AtomButton>
        </div>
    </div>
    <MoleculeModal :close-text="$t('helper.actions.abort')" @close="reset" v-model="showForm" size="md">
        <template #modal-headline>{{ $t('profile.security.change_password') }}</template>
        <template #content>
            <MoleculeLoader v-if="actionIsRunning" size="medium" />
            <MoleculeForm @submit.prevent="updatePassword" v-else>
                <div class="row">
                    <div class="col-12">
                        <AtomInput :animated-label="false" class="password" type="password" :label="$t('profile.security.password_old')" v-model="form.password_old" :error="v$.form.password_old.$error">
                            <template #error-message>{{ $t(v$.form.password_old.$errors[0].$message) }}</template>
                        </AtomInput>
                    </div>
                    <div class="col-12 mt-4">
                        <AtomInput :animated-label="false" class="password" type="password" :label="$t('profile.security.password_new')" v-model="form.password" :error="v$.form.password.$error">
                            <template #error-message>{{ $t(v$.form.password.$errors[0].$message, {min: 12, max: 255}) }}</template>
                        </AtomInput>
                    </div>
                    <div class="col-12 mt-4">
                        <AtomInput :animated-label="false" class="password" type="password" :label="$t('profile.security.password_confirmation')" v-model="form.password_confirmation" :error="v$.form.password_confirmation.$error">
                            <template #error-message>{{ $t(v$.form.password_confirmation.$errors[0].$message, {attribute: $t('profile.security.password')}) }}</template>
                        </AtomInput>
                    </div>
                </div>
            </MoleculeForm>
        </template>
        <template #actions>
            <AtomButton @click.prevent="updatePassword" :disabled="actionIsRunning">
                {{ $t('helper.actions.save') }}
            </AtomButton>
        </template>
    </MoleculeModal>
</template>
<script>
    import BaseComponent from "@/components/BaseComponent";
    import {ResponseService, UserService} from "@development/cluster-center";
    import {required, minLength, sameAs, maxLength, userPassword} from '@development/cluster-center/src/utils/validators';

    export default {
        extends: BaseComponent,
        name: 'ChangePassword',
        props: {
            email: String
        },
        data() {
            return {
                form: {
                    password_old: null,
                    password: null,
                    password_confirmation: null,
                },
                currentPassword: '123456789',
                showForm: false,
                vuelidateExternalResults: {
                    form: {
                        password_old: [],
                        password: [],
                        password_confirmation: [],
                    }
                },
            };
        },
        methods: {
            async updatePassword() {
                this.vuelidateExternalResults.form.password_old = [];
                this.vuelidateExternalResults.form.password = [];
                this.vuelidateExternalResults.form.password_confirmation = [];

                await this.resetValidation();

                if (!await this.validate()) {
                    return;
                }

                this.actionIsRunning = true;

                return await UserService.updatePassword(this.form).then(async response => {
                    this.actionIsRunning = false;

                    if (Array.isArray(response) && response[0].response?.status === 422) {
                        let errors = response[0].response.data.errors;

                        if (errors.password_old && errors.password_old[0] === 'The password is incorrect.') {
                            errors.password_old[0] = 'validations.invalid_password';
                        }

                        if (errors.password && errors.password[0].includes('must contain')) {
                            errors.password[0] = 'validations.user_password';
                        }

                        await this.addExternalErrors(errors, this.vuelidateExternalResults.form);

                        return;
                    }

                    ResponseService.globalNotify(this.$i18n.t('profile.security.changed_password_success', {email: this.form.email}), 'success');

                    this.reset();
                });
            },
            reset() {
                this.form = {
                    password_old: null,
                    password: null,
                    password_confirmation: null,
                };
                this.showForm = false;
                this.resetValidation();
            }
        },
        watch: {
            email(val) {
                this.currentEmail = val;
            },
            password() {
                this.hasError = false;
            },
        },
        validations() {
            return {
                form: {
                    password_old: {required},
                    password: {
                        required,
                        minLength: minLength(12),
                        maxLength: maxLength(255),
                        userPassword
                    },
                    password_confirmation: {
                        required,
                        passwordConfirmation: sameAs(this.form.password)
                    },
                }
            };
        }
    };
</script>