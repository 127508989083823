import { createRouter, createWebHistory } from "vue-router";
import { beforeEach } from '@/router/helper';

import auth from "@/routes/auth";
import app from "@/routes/app";
import managed from "@/routes/managed";
import accounting from "@/routes/accounting";
import demo from "@/routes/demo";
import guest from "@/routes/guest";
import {ResponseService} from "@development/cluster-center";
import { mergeArrays } from "@development/cluster-center/src/composables/helper.js";

let usableRoutes = [...auth, ...app, ...managed, ...accounting, ...guest];

if (process.env.VUE_APP_ENV.toUpperCase() === 'DEMO') {
    usableRoutes = demo;
}

const routes = mergeArrays(
    usableRoutes,
    [{
        name: 'error-403',
        path: "/not-allowed",
        component: () => import(/* webpackChunkName: "Errors" */ "@/pages/Errors/NotAllowedPage.vue"),
        meta: {
            title: 'Permission denied'
        }
    }, {
        name: 'error-404',
        path: "/:pathMatch(.*)*",
        component: () => import(/* webpackChunkName: "Errors" */ "@/pages/Errors/NotFoundPage.vue"),
        meta: {
            title: 'Page not found'
        }
    }]
);

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

beforeEach(router);

router.onError(e => {
    const pattern = /Loading\schunk\s(\d+)\sfailed/g;
    const isChunkLoadFailed = e.message.match(pattern);

    if (isChunkLoadFailed) {
        return ResponseService.warning(window.Config.get('i18n').t('app.please_reload'));
    } else {
        throw e;
    }
});

export default router;
