export default {
    de: {
        gender: 'Geschlecht',
        firstname: 'Vorname',
        surname: 'Nachname',
        company: 'Unternehmen',
        language: 'Sprache',
        email: 'E-Mail',
        password: 'Passwort',
        password_confirm: 'Passwort bestätigen',
        invalid_token: 'Der angegebene Token ist nicht valide.',
        success: 'Sie haben Sich erfolgreich registriert. Sie können sich nun einloggen.',
        ops_invitation: {
            description: '<p>Vielen Dank, dass Sie die Einladung annehmen. Bitte ändern Sie im Folgenden Ihr Passwort.</p><p>Sie werden im nächsten Schritt in die Benutzerverwaltung weitergeleitet und können dort Ihre Daten ändern.</p>',
            expired_token: 'Einladungstoken abgelaufen?',
            success: 'Sie haben erfolgreich das neue Passwort hinterlegt. Sie können sich nun einloggen.'
        }
    },
    en: {
        gender: 'Gender',
        firstname: 'Firstname',
        surname: 'Surname',
        company: 'Company',
        language: 'Language',
        email: 'Email',
        password: 'Password',
        password_confirm: 'Confirm password',
        invalid_token: 'The given token seems to be invalid.',
        success: 'You have successfully registered. You can now log in.',
        ops_invitation: {
            description: '<p>Thank you for accepting the invitation. Please change your password below.</p><p>In the next step you will be redirected to the user management where you can change your data.</p>',
            expired_token: 'Invitation token expired?',
            success: 'You have successfully set the new password. You can now log in.'
        }
    }
};
